/**
  Exporting default style for displaying grid tiles
 */
export const gridTileStyle = {
  color: "#222222",
  weight: 2,
  opacity: 0.6,
  fillOpacity: 0,
};

export const selectedGridTileStyle = {
  color: "#ff0000",
  weight: 3,
  fillOpacity: 0,
};

/**
  Grid tiles outline
 */
export const outlineStyle = {
  fill: false,
  color: "#000000",
  weight: 1,
  dashArray: 3,
};
